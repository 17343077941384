import { Observer } from "mobx-react-lite"
import * as React from "react"
import CTAButton from "../components/CTAButton/CTAButton"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import GridRow from "../components/GridRow/GridRow"
import GridRowEndColumn from "../components/GridRow/GridRowEndColumn"
import Line from "../components/Line/Line"
import PageEndCTASection from "../components/PageEndCTASection/PageEndCTASection"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import ProjectArchive from "../components/ProjectArchive/ProjectArchive"
import Spacer from "../components/Spacer/Spacer"
import StandardTextBlock from "../components/StandardTextBlock/StandardTextBlock"
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef"
import Widowless from "../components/Widowless/Widowless"
import { Category } from "../constants/categories.constants"
import { useAppContext } from "../controllers/app.controller"
import { PageComponent } from "../types/gatsby.types"
import { isIE } from "../utils/browsers.utils"
import './websites.scss'

const PageWebsites: PageComponent = props => {

  const { UI } = useAppContext();
  
  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className="PageWebsites"
      title="Websites"
      description="AxonDivision provides a full range of web design and development services with the latest technologies."
    >

      {/* <DecoAxonLines
        id="WebsitesPageDecoAxonLines"
        enableParallaxEffect
        paths={{
          FG: 'M-66 291L87.6049 280.821C95.1958 280.318 102.415 284.165 106.23 290.747L127.093 326.735C133.637 338.024 149.065 340.124 158.388 330.995L178.786 311.022C184.396 305.529 192.701 303.826 200.02 306.669L305.448 347.62C307.795 348.532 310.293 348.992 312.811 348.977L472.423 348.01C473.473 348.003 474.52 347.914 475.556 347.744L745.274 303.274C750.241 302.455 755.334 303.539 759.537 306.31L836.62 357.134C841.321 360.233 847.11 361.208 852.566 359.818L1050.1 309.502C1053.95 308.523 1058 308.709 1061.74 310.038L1298 394',
          R: 'M-46 382L29.403 316.022C32.3994 313.401 36.11 311.733 40.0599 311.232L173.185 294.37C179.927 293.516 186.641 296.144 191.012 301.348L222.277 338.567C224.709 341.463 227.912 343.61 231.515 344.758L278.497 359.738C287.97 362.758 298.223 358.362 302.564 349.417L436.736 73.0243C441.372 63.4737 452.663 59.2096 462.455 63.3113L705.488 165.11C708.452 166.352 711.658 166.862 714.861 166.595C772.276 161.815 879.113 153 883 153C886.585 153 1018.71 206.825 1046.07 217.988C1049.35 219.327 1052.85 219.738 1056.36 219.24L1305 184',
          G: 'M-147 304L37.0406 288.502C40.9285 288.174 44.8272 288.991 48.2566 290.852L128.573 334.428C133.901 337.319 140.252 337.637 145.842 335.294L391.411 232.372C401.258 228.245 412.609 232.579 417.199 242.219L504.287 425.103C510.866 438.919 529.89 440.53 538.699 428.017L624.091 306.712C625.355 304.916 626.906 303.341 628.682 302.05L791.048 183.965C797.662 179.155 806.54 178.856 813.462 183.212L965.788 279.057C974.006 284.229 984.752 282.749 991.268 275.55L1138.19 113.215C1140.68 110.46 1143.89 108.453 1147.46 107.422L1315 59',
          B: 'M-35 329L256.491 288.625C259.459 288.213 262.482 288.475 265.336 289.389L389 329L610.21 416.905C615.193 418.885 620.763 418.781 625.669 416.617L848.901 318.132C853.468 316.117 858.623 315.883 863.354 317.476L1047.96 379.621C1054.24 381.734 1061.16 380.601 1066.44 376.595L1274 219',
        }} 
        opacity={.85}
      /> */}

      <TricolorMorphDef
        mobile={{
          R: 'M117.471 73H114.059V76.3913H110.647V79.7826V83.1739V86.5652V89.9565V93.3478V96.7391V100.13V103.522V106.913V110.304H107.235V106.913H103.824H100.412H97V110.304V113.696V117.087H100.412V120.478H103.824V123.87V127.261H107.235V130.652V134.043H110.647V137.435V140.826H114.059V144.217V147.609V151H117.471H120.882H124.294H127.706H131.118H134.529H137.941H141.353H144.765H145.106H148.176H148.518V147.609V144.217V140.826H151.588V137.435V134.043V130.652H155V127.261V123.87V120.478V117.087V113.696V110.304V106.913H151.588V103.522H148.176V100.13H144.765H141.353V96.7391H137.941H134.529H131.118V93.3478H127.706H124.294V89.9565V86.5652V83.1739V79.7826V76.3913H120.882V73H117.471ZM120.882 76.3913V79.7826V83.1739V86.5652V89.9565V93.3478V96.7391V100.13V103.522V106.913V110.304H124.294V106.913V103.522V100.13V96.7391H127.706H131.118V100.13V103.522V106.913V110.304H134.529V106.913V103.522V100.13H137.941H141.353V103.522V106.913V110.304V113.696H144.765V110.304V106.913V103.522H148.176V106.913H151.588V110.304V113.696V117.087V120.478V123.87V127.261V130.652H148.176V134.043V137.435V140.826H145.106V144.217V147.609H144.765H141.353H137.941H134.529H131.118H127.706H124.294H120.882H117.471V144.217V140.826H114.059V137.435V134.043H110.647V130.652V127.261H107.235V123.87V120.478H103.824V117.087H100.412V113.696V110.304H103.824H107.235V113.696H110.647V117.087H114.059V113.696V110.304V106.913V103.522V100.13V96.7391V93.3478V89.9565V86.5652V83.1739V79.7826V76.3913H117.471H120.882Z',
          G: 'M351.471 208H348.059V211.391H344.647V214.783V218.174V221.565V224.957V228.348V231.739V235.13V238.522V241.913V245.304H341.235V241.913H337.824H334.412H331V245.304V248.696V252.087H334.412V255.478H337.824V258.87V262.261H341.235V265.652V269.043H344.647V272.435V275.826H348.059V279.217V282.609V286H351.471H354.882H358.294H361.706H365.118H368.529H371.941H375.353H378.765H379.106H382.176H382.518V282.609V279.217V275.826H385.588V272.435V269.043V265.652H389V262.261V258.87V255.478V252.087V248.696V245.304V241.913H385.588V238.522H382.176V235.13H378.765H375.353V231.739H371.941H368.529H365.118V228.348H361.706H358.294V224.957V221.565V218.174V214.783V211.391H354.882V208H351.471ZM354.882 211.391V214.783V218.174V221.565V224.957V228.348V231.739V235.13V238.522V241.913V245.304H358.294V241.913V238.522V235.13V231.739H361.706H365.118V235.13V238.522V241.913V245.304H368.529V241.913V238.522V235.13H371.941H375.353V238.522V241.913V245.304V248.696H378.765V245.304V241.913V238.522H382.176V241.913H385.588V245.304V248.696V252.087V255.478V258.87V262.261V265.652H382.176V269.043V272.435V275.826H379.106V279.217V282.609H378.765H375.353H371.941H368.529H365.118H361.706H358.294H354.882H351.471V279.217V275.826H348.059V272.435V269.043H344.647V265.652V262.261H341.235V258.87V255.478H337.824V252.087H334.412V248.696V245.304H337.824H341.235V248.696H344.647V252.087H348.059V248.696V245.304V241.913V238.522V235.13V231.739V228.348V224.957V221.565V218.174V214.783V211.391H351.471H354.882Z',
          B: 'M74.1176 475H70.7647V478.391H67.4118V481.783V485.174V488.565V491.957V495.348V498.739V502.13V505.522V508.913V512.304H64.0588V508.913H60.7059H57.3529H54V512.304V515.696V519.087H57.3529V522.478H60.7059V525.87V529.261H64.0588V532.652V536.043H67.4118V539.435V542.826H70.7647V546.217V549.609V553H74.1176H77.4706H80.8235H84.1765H87.5294H90.8824H94.2353H97.5882H100.941H101.276H104.294H104.629V549.609V546.217V542.826H107.647V539.435V536.043V532.652H111V529.261V525.87V522.478V519.087V515.696V512.304V508.913H107.647V505.522H104.294V502.13H100.941H97.5882V498.739H94.2353H90.8824H87.5294V495.348H84.1765H80.8235V491.957V488.565V485.174V481.783V478.391H77.4706V475H74.1176ZM77.4706 478.391V481.783V485.174V488.565V491.957V495.348V498.739V502.13V505.522V508.913V512.304H80.8235V508.913V505.522V502.13V498.739H84.1765H87.5294V502.13V505.522V508.913V512.304H90.8824V508.913V505.522V502.13H94.2353H97.5882V505.522V508.913V512.304V515.696H100.941V512.304V508.913V505.522H104.294V508.913H107.647V512.304V515.696V519.087V522.478V525.87V529.261V532.652H104.294V536.043V539.435V542.826H101.276V546.217V549.609H100.941H97.5882H94.2353H90.8824H87.5294H84.1765H80.8235H77.4706H74.1176V546.217V542.826H70.7647V539.435V536.043H67.4118V532.652V529.261H64.0588V525.87V522.478H60.7059V519.087H57.3529V515.696V512.304H60.7059H64.0588V515.696H67.4118V519.087H70.7647V515.696V512.304V508.913V505.522V502.13V498.739V495.348V491.957V488.565V485.174V481.783V478.391H74.1176H77.4706Z',
        }}
        desktop={{
          R: "M867 140H862V145H857V150V155V160V165V170V175V180V185V190V195H852V190H847H842H837V195V200V205H842V210H847V215V220H852V225V230H857V235V240H862V245V250V255H867H872H877H882H887H892H897H902H907H907.5H912H912.5V250V245V240H917V235V230V225H922V220V215V210V205V200V195V190H917V185H912V180H907H902V175H897H892H887V170H882H877V165V160V155V150V145H872V140H867ZM872 145V150V155V160V165V170V175V180V185V190V195H877V190V185V180V175H882H887V180V185V190V195H892V190V185V180H897H902V185V190V195V200H907V195V190V185H912V190H917V195V200V205V210V215V220V225H912V230V235V240H907.5V245V250H907H902H897H892H887H882H877H872H867V245V240H862V235V230H857V225V220H852V215V210H847V205H842V200V195H847H852V200H857V205H862V200V195V190V185V180V175V170V165V160V155V150V145H867H872Z",
          G: "M239 278H234V283H229V288V293V298V303V308V313V318V323V328V333H224V328H219H214H209V333V338V343H214V348H219V353V358H224V363V368H229V373V378H234V383V388V393H239H244H249H254H259H264H269H274H279H279.5H284H284.5V388V383V378H289V373V368V363H294V358V353V348V343V338V333V328H289V323H284V318H279H274V313H269H264H259V308H254H249V303V298V293V288V283H244V278H239ZM244 283V288V293V298V303V308V313V318V323V328V333H249V328V323V318V313H254H259V318V323V328V333H264V328V323V318H269H274V323V328V333V338H279V333V328V323H284V328H289V333V338V343V348V353V358V363H284V368V373V378H279.5V383V388H279H274H269H264H259H254H249H244H239V383V378H234V373V368H229V363V358H224V353V348H219V343H214V338V333H219H224V338H229V343H234V338V333V328V323V318V313V308V303V298V293V288V283H239H244Z",
          B: "M984 443H979V448H974V453V458V463V468V473V478V483V488V493V498H969V493H964H959H954V498V503V508H959V513H964V518V523H969V528V533H974V538V543H979V548V553V558H984H989H994H999H1004H1009H1014H1019H1024H1024.5H1029H1029.5V553V548V543H1034V538V533V528H1039V523V518V513V508V503V498V493H1034V488H1029V483H1024H1019V478H1014H1009H1004V473H999H994V468V463V458V453V448H989V443H984ZM989 448V453V458V463V468V473V478V483V488V493V498H994V493V488V483V478H999H1004V483V488V493V498H1009V493V488V483H1014H1019V488V493V498V503H1024V498V493V488H1029V493H1034V498V503V508V513V518V523V528H1029V533V538V543H1024.5V548V553H1024H1019H1014H1009H1004H999H994H989H984V548V543H979V538V533H974V528V523H969V518V513H964V508H959V503V498H964H969V503H974V508H979V503V498V493V488V483V478V473V468V463V458V453V448H984H989Z",
        }}
      />
      <DefaultPageHeader
        id="WebsitesPageHeader"
        className="WebsitesPageHeader"
        title="Websites"
      >
        <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>Modern websites are comprised of thousands of moving parts, and businesses have the most radical ideas they need to present to the world in a highly competitive online environment.</Widowless>
          </StandardTextBlock>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>Our talented design team works with both you and our excellent engineers to give your visitors an unparalleled digital experience.</Widowless>
          </StandardTextBlock>
          { 
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }
          <GridRowEndColumn>
            <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
            // gaEvent={{ event: "contact-button--hero" }}
            >Contact Us</CTAButton>
          </GridRowEndColumn>
        </GridRow>
      </DefaultPageHeader>

      <ProjectArchive
        baseUrl="/websites"
        categories={[Category.websites]}
      />

      <Spacer size="pageMargin" />

      <Line />

      <PageEndCTASection preSelectTopic="websites" />
      {/* <ContactForm preSelectedTopics={['websites']} /> */}

    </PageTemplate>
  )} />
  
}

export default PageWebsites
