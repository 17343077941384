import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { CannonballImagesQuery } from "../../types/generated";

export const ProjectMetaInfoCannonball: ProjectMetaInfo = {
  name: "cannonball",
  displayName: "Cannonball Ireland",
  abbreviation: "Cannonball",
  subtitle: "Cannonball Road Trips",
  description: "Cannonball is the most unique super car fraternity in the world and it all began in Ireland … of the hundred thousand welcomes!",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.entertainmentAndEvents,
    Sector.motorsports,
  ],
  url: "https://cannonball.ie/",
  hasPage: false,
  keywords: [
    'Cannonball',
    'Cannonball.ie',
    'web design',
    'web development',
    'WordPress',
    'super cars',
    'events',
  ],
}
export const useCannonballInfo = () => {
  const CannonballImages: CannonballImagesQuery = useStaticQuery(graphql`
    query CannonballImages {
      featuredImage: file(relativePath: {eq: "images/cannonball/cannonball-ireland-2019-race-car-among-cheering-crowd.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/cannonball/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'Cannonball Ireland Homepage Screenshot',
      alt: 'Cannonball Ireland Homepage Screenshot, desktop version',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoCannonball,
    featuredImage: {
      image: CannonballImages.featuredImage!.childImageSharp,
      title: 'Cannonball Ireland Project Featured Image',
      alt: 'Cannonball Ireland 2019: A race car among cheering crowd',
    },
    gallery: CannonballImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}