import { Expo, TweenLite } from 'gsap';
import * as React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { Category } from "../../constants/categories.constants";
import { getSectorFromSlug, getSectorName, Sector } from '../../constants/sectors.constants';
import { ProjectMetaInfoMajesticIreland, useMajesticIrelandInfo } from "../../content/projects/majestic-ireland.project";
import { useAllProjectMetaInfo } from "../../content/projects/_projects.index";
import { useAppContext } from '../../controllers/app.controller';
import { getEntryPagePath } from "../../utils/entry.utils";
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import CTAButton from '../CTAButton/CTAButton';
import EntryMetaInfoHeader from "../EntryMetaInfoHeader/EntryMetaInfoHeader";
import GridRowEndColumn from '../GridRow/GridRowEndColumn';
import PageSection from '../PageSection/PageSection';
import ProjectEntryList from '../ProjectEntryList/ProjectEntryList';
import QuoteSection from "../QuoteSection/QuoteSection";
import SectorSelector from "../SectorSelector/SectorSelector";
import SimpleImageFrame from "../SimpleImageFrame/SimpleImageFrame";
import Spacer from '../Spacer/Spacer';
import './ProjectArchive.scss';

type ProjectArchiveProps = {
  categories?: Category[],
  baseUrl?: string,
}

/**
 * Seen on the "websites" page, lists all projects with a sector filter.
 * It can accept a list of categories as filters.
 */
const ProjectArchive: React.FC<ProjectArchiveProps> = props => {

  const { UI } = useAppContext();
  const [ sectorParam ] = useQueryParam('sector', StringParam);
  const projects = useAllProjectMetaInfo({ categories: props.categories });
  const sector = getSectorFromSlug(sectorParam);
  const showAll = !sectorParam || sectorParam === Sector.all;
  const filteredProjects = showAll ? projects : projects.filter(p => p.sectors.includes(sector!));
  const containerRef = React.useRef<HTMLHeadingElement>(null);
  const headingRef = React.useRef<HTMLHeadingElement>(null);
  const listRef = React.useRef<HTMLUListElement>(null);
  
  const animateContentEnter = (towards: 'left' | 'right') => {
    if (!headingRef.current) return;
    const originXSign = towards === 'left' ? -1 : 1;
    TweenLite.from(headingRef.current, .5, {
      opacity: 0,
      x: originXSign * 120,
      ease: Expo.easeOut,
    })
    if (!listRef.current) return;
    TweenLite.set(listRef.current.children, { opacity: 0 })
    TweenLite.fromTo(listRef.current.children, UI.viewport.width >= 768 ? .62 : .38, {
      opacity: 0,
      x: originXSign * 80,
    }, {
      opacity: 1,
      x: 0,
      ease: Expo.easeOut,
      stagger: .05,
    })
    if (containerRef.current) {
      const containerOffsetTop = containerRef.current?.offsetTop ?? 0;
      // const navBarHeight = document.getElementById('NavBar')?.clientHeight ?? 0;
      // const fontSize = getFontSize(containerRef.current, 14);
      window.scrollTo({ 
        /** the SectorSelector component is sticky at (navBarHeight - 2em). */
        // top: Math.max(0, containerOffsetTop - navBarHeight + fontSize * 2),
        top: containerOffsetTop,
      });
    }
  }

  return <div className="ProjectArchive" ref={containerRef}>
    <PageSection id="ProjectArchiveSectorSelectorSection" className="ProjectArchiveSectorSelectorSection">
      <SectorSelector baseUrl={props.baseUrl} onChange={animateContentEnter} />
    </PageSection>
    
    { showAll && <FeaturedWebsites /> }
    <PageSection id="ProjectArchiveSectorDetailsSection" className="ProjectArchiveSectorDetailsSection">
      { showAll ? <Spacer size="pageMargin" /> : <header className="ProjectArchiveSectorDetailsSectionHeader">
        <h2 className="ProjectArchiveSectorDetailsSectionHeading" ref={headingRef}>{getSectorName(sectorParam)}</h2>
      </header> }
      <ProjectEntryList ref={listRef} projects={filteredProjects} />
    </PageSection>
    {/* <Spacer size="pageMargin" />
    <Line />
    <Spacer size="pageMargin" /> */}
  </div>
}

const FeaturedWebsites = () => {
  const majesticIreland = useMajesticIrelandInfo();
  const image = majesticIreland.gallery![0];
  return <>
    <PageSection id="FeaturedWebsitesTopSiteSection" className="FeaturedWebsitesTopSiteSection">
      <figure className="FeaturedWebsitesSectionTopSiteFigure">
        {image && <AnimatedLink
          to={getEntryPagePath(ProjectMetaInfoMajesticIreland)}
          title={ProjectMetaInfoMajesticIreland.displayName}
        >
          <SimpleImageFrame
            image={image}
            title="Majestic Ireland Website Design: Homepage Desktop"
          />
        </AnimatedLink>}
        <figcaption>
          <div className="FeaturedWebsitesSectionTopSiteFigureFigcaptionHeader">
            <svg width="16" height="40" viewBox="0 0 16 40">
              <path d="M6.13208 5.27984L6.0732 5.22096L6.01866 5.2755L5.03575 7.52708L2.94431 9.6256L0.56622 7.24751L7.81373 0L15.0612 7.24751L12.6831 9.6256L10.5917 7.52708L9.6088 5.2755L9.55426 5.22096L9.49529 5.27992L9.49521 5.27992L9.49521 39.6363L6.13208 39.6363L6.13208 5.27984Z" fill="currentColor" />
            </svg>
            <EntryMetaInfoHeader entry={ProjectMetaInfoMajesticIreland} />
          </div>
          <GridRowEndColumn>
            <CTAButton
              to={getEntryPagePath(majesticIreland)}
              title={majesticIreland.displayName}
              drawLine="right"
              ribbons
            >View Details</CTAButton>
          </GridRowEndColumn>
        </figcaption>
      </figure>
    </PageSection>
    <QuoteSection
      id="FeaturedWebsitesQuoteSection"
      className="FeaturedWebsitesQuoteSection"
      content={[
        'Not just good enough.',
        'Let’s build a site that your customers love.'
      ]}
      addLineBefore
      addLineAfter
    />
  </>
}

export default ProjectArchive;