import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { Earth2EarthImagesQuery } from "../../types/generated";

export const ProjectMetaInfoEarth2Earth: ProjectMetaInfo = {
  name: "earth2earth",
  displayName: "earth2earth",
  abbreviation: "earth2earth",
  subtitle: "Innovative environment-friendly product supplier",
  description: "earth2earth® is a brand of Thorn Environmental Ltd one of the largest and most innovative producers of refuse sacks, bin-liners and packaging films in Ireland. We designed and developed a minimalistic website for earth2earth to showcase their excellent products.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
  ],
  url: "https://www.earth2earth.com/",
  hasPage: false,
  keywords: [
    'earth2earth',
    'web design',
    'web development',
    'WordPress',
  ],
}
export const useEarth2EarthInfo = () => {
  const Earth2EarthImages: Earth2EarthImagesQuery = useStaticQuery(graphql`
    query Earth2EarthImages {
      featuredImage: file(relativePath: {eq: "images/earth2earth/earth2earth-background-sugarcanes.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
          relativeDirectory: {eq: "images/earth2earth/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'Earth2Earth Product Page Screenshot',
      alt: 'Earth2Earth Product Page Screenshot, desktop version',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoEarth2Earth,
    featuredImage: {
      image: Earth2EarthImages.featuredImage!.childImageSharp!,
      alt: 'earth2earth Project Featured Image',
      title: 'earth2earth Project Featured Image',
    },
    gallery: Earth2EarthImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}