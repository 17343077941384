import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ImageDataLike } from '../../types/gatsby.types';
import GatsbyImageAdaptor from '../GatsbyImageAdaptor/GatsbyImageAdaptor';
import './SimpleImageFrame.scss';

type SimpleImageFrameProps = {
  image: ImageDataLike,
  title: string,
  alt?: string,
}

/**
 * Just a simple image frame for Gatsby Image,
 * but adding a backdrop color and forcing the image to always be 100% width of the parent container
 */
const SimpleImageFrame: React.FC<SimpleImageFrameProps> = props => {
  return <Observer children={() => (
    <div className="SimpleImageFrame">
      { props.image && <GatsbyImageAdaptor
        image={props.image}
        title={props.title}
        alt={props.alt ?? props.title}
      /> }
    </div>
  )} />
}

export default SimpleImageFrame;