import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { TougherOilImagesQuery } from "../../types/generated";

export const ProjectMetaInfoTougherOil: ProjectMetaInfo = {
  name: "tougheroil",
  displayName: "Tougher Oil",
  abbreviation: "Tougher Oil",
  subtitle: "Established Irish Oil & Fuels Supplier",
  description: "Tougher Oil is a new supplier of home-heating oil in Ireland. We cooperated with their enthusiastic team and built a brand new, bespoke website for easily placing orders, together with one of the most vibrant brand identities we have designed so far.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.energy,
    Sector.eCommerceAndRetail,
  ],
  url: "https://www.tougheroil.ie/",
  hasPage: false,
  keywords: [
    'TougherOil',
    'web design',
    'web development',
    'laravel',
    'eCommerce',
  ],
}
export const useTougherOilInfo = () => {
  const TougherOilImages: TougherOilImagesQuery = useStaticQuery(graphql`
    query TougherOilImages {
      featuredImage: file(relativePath: {eq: "images/tougheroil/tougheroil-project-featured-image-truck-driving-in-sunset.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/tougheroil/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'TougherOil Homepage Screenshot',
      alt: 'TougherOil Homepage Screenshot, desktop version',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoTougherOil,
    featuredImage: {
      image: TougherOilImages.featuredImage!.childImageSharp,
      title: 'TougherOil Project Featured Image',
      alt: 'A red fuel-delivery truck driving in the sunset',
    },
    gallery: TougherOilImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}