import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { BunzlCssImagesQuery } from "../../types/generated";

export const ProjectMetaInfoBunzlCSS: ProjectMetaInfo = {
  name: "bunzlcss",
  displayName: "Bunzl CSS",
  abbreviation: "Bunzl CSS",
  subtitle: "Bunzl Cleaning & Safety Supplies",
  description: "Bunzl Cleaning & Safety Supplies is a trading division of Bunzl Ireland Ltd. and forms part of Bunzl PLC, a multinational distribution and outsourcing company with annual revenues in excess of GBP £7.4bn and a constituent of the FTSE 100 index. We designed and developed this clean and informative website to bring their online presence to the next level.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
  ],
  url: "https://www.bunzlcss.com/",
  hasPage: false,
  keywords: [
    'Bunzl CSS',
    'web design',
    'web development',
    'WordPress',
  ],
}
export const useBunzlCSSInfo = () => {
  const BunzlCSSImages: BunzlCssImagesQuery = useStaticQuery(graphql`
    query BunzlCSSImages {
      featuredImage: file(relativePath: {eq: "images/bunzlcss/surface-cleaning-with-antibacterial-sanitizing-spray.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
          relativeDirectory: {eq: "images/bunzlcss/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'Bunzl CSS Homepage Screenshot',
      alt: 'Bunzl CSS Homepage Screenshot, desktop version',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoBunzlCSS,
    featuredImage: {
      image: BunzlCSSImages.featuredImage!.childImageSharp!,
      alt: 'Bunzl CSS Project Featured Image',
      title: 'Bunzl CSS Project Featured Image',
    },
    gallery: BunzlCSSImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}