import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { ElyxrImagesQuery } from "../../types/generated";

export const ProjectMetaInfoElyxr: ProjectMetaInfo = {
  name: "elyxr",
  displayName: "Elyxr Audio",
  abbreviation: "Elyxr",
  subtitle: "Lifestyle Audio Equipment Company",
  description: "Elyxr Audio is a modern, luxury audio brand that specialises in earphone and headphone technology. A refreshed, contemporary and responsive website was needed to accompany their launch of high quality earphones. With free reign over the design direction, we designed the new site for Elyxr with a focus on the brand, engaging interaction and conversion into sales.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
    Sector.musicAndAudio,
  ],
  hasPage: false,
  keywords: [
    'Elyxr',
    'Elyxr Audio',
    'web design',
    'web development',
    'WordPress',
    'eCommerce',
  ],
}
export const useElyxrInfo = () => {
  const ElyxrImages: ElyxrImagesQuery = useStaticQuery(graphql`
    query ElyxrImages {
      featuredImage: file(relativePath: {eq: "images/elyxr/elyxr-fusion-featured-image.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/elyxr/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "Elyxr Audio Homepage Design, explained as 3D Layers",
      alt: "Elyxr Audio Homepage Design, explained as 3D Layers",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoElyxr,
    featuredImage: {
      image: ElyxrImages.featuredImage!.childImageSharp,
      title: 'Elyxr Audio',
      alt: 'Elyxr Audio product lifestyle photo: a musician wearing a pair of black Elyxr Fusion over-ear headphones around his neck.'
    },
    gallery: ElyxrImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}