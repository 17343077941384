import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { XMusicImagesQuery } from "../../types/generated";

export const ProjectMetaInfoXMusic: ProjectMetaInfo = {
  name: "xmusic",
  displayName: "XMusic",
  abbreviation: "XMusic",
  subtitle: "Ireland’s largest musical instruments stores",
  description: `XMusic is Ireland's largest musical instrument megastore. We designed and developed the new eCommerce website for XMusic, together with a branding refresh based on their original logo.`,
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
    Sector.musicAndAudio,
  ],
  url: "https://xmusic.ie/",
  hasPage: false,
  keywords: [
    'XMusic',
    'xmusic.ie',
    'web design',
    'web development',
    'WordPress',
    'musical instruments',
    'eCommerce',
    'WooCommerce',
  ],
}
export const useXMusicInfo = () => {
  const XMusicImages: XMusicImagesQuery = useStaticQuery(graphql`
    query XMusicImages {
      featuredImage: file(relativePath: {eq: "images/xmusic/xmusic-red-cow-storefront-twilight.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/xmusic/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "XMusic Homepage Screenshot",
      alt: "XMusic Homepage Screenshot, desktop version",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoXMusic,
    featuredImage: {
      image: XMusicImages.featuredImage!.childImageSharp,
      title: 'XMusic Storefront',
      alt: 'XMusic Storefront at dusk (Red Cow store, Dublin)'
    },
    gallery: XMusicImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}