import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { MkiImagesQuery } from "../../types/generated";

export const ProjectMetaInfoMKI: ProjectMetaInfo = {
  name: "motorsport-knowledge-institute",
  displayName: "Motorsport Knowledge Institute",
  abbreviation: "Motorsport Knowledge Institute",
  subtitle: "Global Motorsport Multilingual Training Services Provider",
  description: "The Motorsport Knowledge Institute (MKI) was established in 2011 as the dedicated education, training and research division of the Emirates Motorsports Organization (EMSO). This project includes MKI's main website, as well as a host of e-learning club subsites in different countries.",
  url: 'https://motorsportknowledgeinstitute.org/',
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.eLearning,
    Sector.motorsports,
    // Sector.construction,
  ],
  hasPage: false,
  hashtags: [
  ],
  keywords: [
    'web design',
    'WordPress',
    'eLearning',
  ],
}
export const useMKIInfo = () => {
  const MKIImages: MkiImagesQuery = useStaticQuery(graphql`
    query MKIImages {
      featuredImage: file(relativePath: {eq: "images/motorsport-knowledge-institute/yas-marina-circuit-vehicle-recovery-crane.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/motorsport-knowledge-institute/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560)
            }
          }
        }
      }
    }`
  )
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "Motorsport Knowledge Institute: Homepage Screenshot",
      alt: "Motorsport Knowledge Institute: Homepage Screenshot, desktop version",
    },
    // {
    //   title: "Motorsport Knowledge Institute",
    //   alt: "Motorsport Knowledge Institute",
    // },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoMKI,
    featuredImage: {
      image: MKIImages.featuredImage!.childImageSharp,
      title: 'Motorsport Knowledge Institute',
      alt: 'Yas Marina Cicruit: Vehicle Recovery Scene',
    },
    gallery: MKIImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}