import { Back, TweenLite } from 'gsap';
import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { sectorDefinitions, sectorsPreferredOrder } from '../../constants/sectors.constants';
import { isBrowser, isBuildTime } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useStore } from '../../utils/mobx.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import Line from '../Line/Line';
import './SectorSelector.scss';

type SectorSelectorProps = {
  baseUrl?: string,
  onChange?: (animationOrigin: 'left' | 'right') => void;
}

const SectorSelector: React.FC<SectorSelectorProps> = props => {

  const [sector, setSector] = useQueryParam('sector', StringParam);

  const s = useStore(() => ({
    previousSector: sector,
    sector,
    lastPositionLeft: 0,
  }))

  const selectorRef = useObservableRef();
  const selectorInnerRef = useObservableRef();
  const highlighterRef = useObservableRef();

  useEffect(action(() => { s.sector = sector }));
  
  useOnMount(() => {
    const updateHighlighterPosition = action(() => {
      if (!highlighterRef.current || !selectorInnerRef.current) return;
      const targetTab = selectorRef.current?.querySelector(`a[data-slug="${s.sector ?? 'all'}"]`);
      if (!targetTab) return;
      const { paddingLeft } = getComputedStyle(targetTab);
      const { left } = targetTab.getBoundingClientRect();
      const paddingX = parseInt(paddingLeft);
      const parentPaddingLeft = parseInt(getComputedStyle(selectorInnerRef.current).paddingLeft);
      const animationOrigin = left - s.lastPositionLeft >= 0 ? 'left' : 'right';
      s.lastPositionLeft = left;
      if (s.previousSector !== s.sector && !!s.sector) props.onChange?.(animationOrigin);
      s.previousSector = s.sector;
      if (isBuildTime) {
        TweenLite.set(highlighterRef.current, {
          width: targetTab.clientWidth - paddingX * 2,
          left: left + paddingX * 2 - parentPaddingLeft + (document.querySelector('.SectorSelector')?.scrollLeft ?? 0),
        });
      } else {
        TweenLite.to(highlighterRef.current, .25, {
          ease: Back.easeOut,
          width: targetTab.clientWidth - paddingX * 2,
          left: left + paddingX * 2 - parentPaddingLeft + (document.querySelector('.SectorSelector')?.scrollLeft ?? 0),
        });
      }
    })
    updateHighlighterPosition();
    if (isBuildTime) return;
    const d = makeDisposerController();
    window.addEventListener('resize', updateHighlighterPosition);
    d.add(reaction(() => s.sector, updateHighlighterPosition));
    d.add(() => window.removeEventListener('resize', updateHighlighterPosition));
    return d.disposer;
  })

  return <Observer children={() => (
    <div className="SectorSelector" data-selected-sector={sector} ref={selectorRef}>
      <div className="SectorSelectorInner" ref={selectorInnerRef}>
        <div className="SectorSelectorLinkGroup">
          { sectorsPreferredOrder.map(sect => {
            const def = sectorDefinitions[sect];
            return <AnimatedLink
              key={def.slug}
              data-slug={def.slug}
              className={sector === def.slug || !sector && def.slug === 'all' ? 'active' : undefined}
              to={`${props.baseUrl ?? ''}/?sector=${def.slug}`}
              title={`View Sector ${def.name}`}
              onClick={() => setSector(def.slug)}
            >{def.abbreviation ?? def.name}</AnimatedLink>
          }) }
          { isBrowser && <div className="SectorSelectorHighlighter" ref={highlighterRef} />}
        </div>
        <Line />
      </div>
    </div>
  )} />

}

export default SectorSelector;