import { Category } from "../../constants/categories.constants";
import { hasIntersection } from "../../utils/array.utils";
import { useAAAIDPAnimationInfo } from "./aaa-idp-animation.project";
import { useBunzlCSSInfo } from "./bunzlcss.project";
import { useCannonballInfo } from "./cannonball.project";
import { useCarnetDePassagesInfo } from "./carnet-de-passages.project";
import { useCarraighillInfo } from "./carraighill.project";
import { useCelticWoodlandYogaFestivalInfo } from "./celtic-woodland-yoga-festival.project";
import { useEarth2EarthInfo } from "./earth2earth.project";
import { useElyxrInfo } from "./elyxr.project";
import { useEscapeToMarlboroughInfo } from "./escape-to-marlborough.project";
import { useGCNInfo } from "./gcn.project";
import { useHopOnHopOffInfo } from "./hoponhopoff.project";
import { useIDPInfo } from "./idp.project";
import { useJimOCallaghanInfo } from "./jim-ocallaghan.project";
import { useKLHInfo } from "./klh.project";
import { useMajesticIrelandInfo } from "./majestic-ireland.project";
import { useMJHudsonBridgeInfo } from "./mjhudson-bridge.project";
import { useMKIInfo } from "./motorsport-knowledge-institute.project";
import { useTheGablesInfo } from "./the-gables.project";
import { useTougherOilInfo } from "./tougheroil.project";
import { useTurn2meInfo } from "./turn2me.project";
import { useXMusicInfo } from "./xmusic.project";

export const useAllProjectMetaInfo = (
  filters?: {
    categories?: Category[]
  }
) => {

  /**
   * The order of the following array will directly influence 
   * how the projects are ordered in the index lists in the `/websites` page
   */
  const projects = [
    useTurn2meInfo(),
    useGCNInfo(),
    useJimOCallaghanInfo(),
    useKLHInfo(),
    useAAAIDPAnimationInfo(),
    useCannonballInfo(),
    useTheGablesInfo(),
    useIDPInfo(),
    useMajesticIrelandInfo(),
    useMJHudsonBridgeInfo(),
    useEscapeToMarlboroughInfo(),
    useHopOnHopOffInfo(),
    useCelticWoodlandYogaFestivalInfo(),
    useXMusicInfo(),
    useTougherOilInfo(),
    useEarth2EarthInfo(),
    useElyxrInfo(),
    useBunzlCSSInfo(),
    useMKIInfo(),
    useCarraighillInfo(),
    useCarnetDePassagesInfo(),
    // useOneStopHandlingInfo(),
  ];

  if (filters?.categories) {
    return projects.filter(p => p.categories.length === 0 || hasIntersection(p.categories, filters.categories!));
  }
  
  return projects;
  
}